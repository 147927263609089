import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../contexts/AppContext";
import axios from "axios";
import Select from "react-select";
import { Modal, ModalHeader, Form, Alert, Spinner } from "react-bootstrap";
import {
  queryBranches,
  queryGroups,
  queryUsers,
  getAllGroupMembers,
} from "../services/poweruserServices";



const Sessions = (props) => {
  const { currentUser } = useAppContext();
  const { learningPlan, sessions, locations,sessionsForCourse,courseIds } = props;
  const [currentRadioValue, setCurrentRadioValue] = useState("users");
  const [defaultListOptions, setDefaultListOptions] = useState([]);
  const [selectBoxValues, setSelectBoxValues] = useState();
  const [selectBoxValuesGroup, setSelectBoxValuesGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState({});
  const [isListBoxLoading, setIsListBoxLoading] = useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [showEnrollConfirm, setShowEnrollConfirm] = useState(false);
  const [selectedCohort, setSelectedCohort] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const enrollSelectBox = useRef(null);
  //excel soft added new parameteres
  const [defaultListGroupOptions, setDefaultListGroupOptions] = useState([]);
  const [showViewSessionConfirm, setShowViewSessionConfirm] = useState(false);
  const [otherSessionList, setotherSessionList] = useState([]);
  const [SessionDtlList, setSessionDtlList] = useState([]);
 
  useEffect(() => {
    if (currentUser.isPowerUser) {
      // let powerUserGroupUsers = getSessionStorageUsers();
      // if (powerUserGroupUsers && powerUserGroupUsers.length > 0) {
      //     setGroupUsers(powerUserGroupUsers);
      //     setDefaultListOptions(powerUserGroupUsers);

      // } else {
      // defaultListOptions.length <= 0 && getPowerUserGroupUsers();
      // added by excel soft and commented the getting getPowerUserGroupUsers
      defaultListGroupOptions.length <= 0 && getPowerUserGroups();
      // }
    }
   
    AddSessionDtlList();
  }, []);
  const AddSessionDtlList = async () => {
    const response =  await axios.post(`${process.env.REACT_APP_API_DOMAIN}/course/sessionsdtl${window.location.search}`, {
      courseIds
         }, { withCredentials: true });

         setSessionDtlList(response.data);
         setLoading(false);
  };
  
  useEffect(() => {
    let resultToast = document.getElementById("resultToast");
    // added new code by excel soft to correcting the dessign issue the enroll complete popup
    let resultToastContainer = document.getElementById("resultToastContainer");
    if (showResult) {
      resultToast.classList.replace("hide", "show");
      resultToastContainer.classList.add(
        "position-fixed",
        "top-50",
        "start-50",
        "translate-middle",
        "d-flex",
        "justify-content-center",
        "align-items-center",
        "vw-100",
        "vh-100"
      );
    } else {
      resultToast.classList.replace("show", "hide");
      resultToastContainer.classList.remove(
        "position-fixed",
        "top-50",
        "start-50",
        "translate-middle",
        "d-flex",
        "justify-content-center",
        "align-items-center",
        "vw-100",
        "vh-100"
      );
    }

    // showResult &&
    //   setTimeout(() => {
    //     setShowResult(false);
    //   }, 5000);
  }, [showResult]);

  const searchUsers = async (searchText) => {
    var data = await queryUsers(currentUser.user_id, searchText);
    return data.map((item) => {
      return { value: item.id, label: item.username, type: "user" };
    });
  };

  const searchGroups = async (searchText) => {
    var data = await queryGroups(currentUser.user_id, searchText);

    return data.map((item) => {
      return { value: item.id, label: item.name, type: "group" };
    });
  };

  const searchBranches = async (searchText) => {
    var data = await queryBranches(currentUser.user_id, searchText);
    return data.map((item) => {
      return { value: item.id, label: item.name, type: "branch" };
    });
  };

  const getPowerUserGroupUsers = async () => {
    setIsListBoxLoading(true);
    let userGroups = await queryGroups(currentUser.user_id);
    let groupId = userGroups[0].id.toString();
    let groupUsers = await getAllGroupMembers(groupId);

    let listGroupUsers = groupUsers.map((item) => {
      return {
        value: item.id,
        label: item.username,
        type: "user",
        email: item.email,
      };
    });

    setGroupUsers(listGroupUsers);
    setDefaultListOptions(listGroupUsers);
    setIsListBoxLoading(false);

    //setSessionStorageUsers(listGroupUsers);
    return listGroupUsers;
  };

  const getPowerUserGroups = async () => {
    setIsListBoxLoading(true);
    let userGroups = await queryGroups(currentUser.user_id);

    var result = userGroups.map((item) => {
      return { value: item.id, label: item.name, type: "group" };
    });

    setDefaultListGroupOptions(result);
    setIsListBoxLoading(false);

    //setSessionStorageUsers(listGroupUsers);
    return result;
  };

  // added by excel soft when power user try to enroll chaging the user group binding the new users to drop down
  const handleSelectChangeGroup = async (selectedOptions) => {
    setIsListBoxLoading(true);
    setSelectBoxValuesGroup(selectedOptions);
    //  console.log(selectedOptions);
    // let userGroups = await queryGroups(currentUser.user_id);
    let groupId = selectedOptions.value.toString();
    let groupUsers = await getAllGroupMembers(groupId);

    let listGroupUsers = groupUsers.map((item) => {
      return {
        value: item.id,
        label: item.username,
        type: "user",
        email: item.email,
      };
    });

    setGroupUsers(listGroupUsers);
    setDefaultListOptions(listGroupUsers);
    setIsListBoxLoading(false);

    //setSessionStorageUsers(listGroupUsers);
    return listGroupUsers;
  };

  // const setSessionStorageUsers = (users) => {
  //     let storageString = JSON.stringify({ userId: currentUser.user_id, groupUsers: users });
  //     sessionStorage.setItem('groupUsers', storageString)
  // }

  // const getSessionStorageUsers = () => {
  //     let storageString = sessionStorage.getItem('groupUsers');
  //     if (storageString) {
  //         let storageObj = JSON.parse(storageString);
  //         if (storageObj.userId == currentUser.user_id) {
  //             return storageObj.groupUsers;
  //         }

  //     }
  //     return;
  // }

  const goBack = () => {
    props.onBackClick();
  };

  const promiseOptions = async (inputValue) => {
    setIsListBoxLoading(true);
    let filteredUsers = groupUsers.filter(
      (x) => x.label.indexOf(inputValue) > -1
    );
    setGroupUsers(filteredUsers);
    setIsListBoxLoading(false);
    // // not used currently
    // // was used for multi selection type
    // switch (currentRadioValue) {
    //     case 'users':
    //         return searchUsers(inputValue);
    //     case 'groups':
    //         return searchGroups(inputValue);
    //     case 'branches':
    //         return searchBranches(inputValue);
    //     default:
    //         break;
    // }
  };

  const handleSelectInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");

    switch (currentRadioValue) {
      case "users":
        return searchUsers(inputValue);
      case "groups":
        return searchGroups(inputValue);
      case "branches":
        return searchBranches(inputValue);
      default:
        break;
    }
  };

  const filterOptions = (candidate, input) => {
    return (
      candidate.data.label.indexOf(input) > -1 ||
      candidate.data.email.indexOf(input) > -1
    );
  };

  const onEnrollClickHandler = (session) => {
    setSelectedCohort(session);
    console.log(session);
    setSelectBoxValues([]);
    if (currentUser.isPowerUser) {
      setShowEnrollModal(true);
    } else {
      setShowEnrollConfirm(true);
    }
  };

  // added by excel soft to bind the sessions details in popup
  const onViewSessionClickHandler = (session) => {
  if(SessionDtlList.length==0)
  {
    setLoading(true);
  }
   
  const sorted = [...session.sessions].sort((a, b) => 
    new Date(a.date_start) - new Date(b.date_start)  // Ascending order
  );
 
    setotherSessionList(sorted);
    setShowViewSessionConfirm(true);
  };

  //added by excel soft to redirect for 1st page when enrolled
  const onEnrolledCompleteOkClickHandler = () => {
    setShowResult(false);
    //props.onBackClick();
    props.onOkClick();
    
  };

  // const viewOtherSessions = async (session) => {
  //   var courseIds = [1, 2];

  //   const response = await axios.post(
  //     `${process.env.REACT_APP_API_DOMAIN}/course/otherSessionss${val}`,
  //     {
  //       courseIds,
  //     },
  //     { withCredentials: true }
  //   );

  // };

  const onUnEnrollClickHandler = async (learningPlanId) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_DOMAIN}/learningplan/enroll${window.location.search}`,
      {
        learningPlanId: learningPlanId,
        userId: 1,
      },
      { withCredentials: true }
    );
  };

  const onModalEnrollClickHandler = async (e) => {
    setLoading(true);
    let users = selectBoxValues.filter((value) => value.type === "user");
    let groups = selectBoxValues.filter((value) => value.type === "group");
    let branches = selectBoxValues.filter((value) => value.type === "branch");

    let userIds = users.map((user) => user.value);
    let groupIds = groups.map((group) => group.value);
    let branchIds = branches.map((branch) => branch.value);

    await enrollInCoursesAndSessions(
      userIds,
      groupIds,
      branchIds,
      selectedCohort
    );
    setLoading(false);
    setShowEnrollModal(false);
  };

  const onConfirmEnrollClick = async () => {
    setLoading(true);
    await enrollUser();
    setLoading(false);
  };

  const enrollUser = async () => {
    var resultObj = {};
    try {
      let userIds = [currentUser.user_id]; //convert to array to use existing endpoint
      const userResp = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/enrollment/users_to_lp${window.location.search}`,
        {
          userIds,
          learningPlanId: learningPlan.id,
        },
        { withCredentials: true }
      );

      let _groupedSessions = await getAllSessions(learningPlan, false);
      let cohort = _groupedSessions.find(
        (x) => x.cohortId === selectedCohort.cohortId
      );
      var selectedSessions = cohort.sessions.map((s) => {
        return { sessionId: s.id, courseId: s.course_id };
      });

      var branchIds = []; //send in request to avoid errors
      var groupIds = []; //send in request to avoid errors

      var sessionResponse = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/enrollment${window.location.search}`,
        {
          userIds,
          groupIds,
          branchIds,
          sessions: selectedSessions,
        },
        { withCredentials: true }
      );

      const existingEnrollments = sessionResponse.data
        .map((d) => d?.errors)
        .flat(1)
        .filter((e) => e?.startsWith("The enrollments already exists"));
      resultObj.existing_enrollments = existingEnrollments;
      resultObj.errors = sessionResponse.data
        .map((d) => d?.errors)
        .flat(1)
        .filter(
          (e) => !e?.startsWith("The enrollments already exists") && e != null
        );

      resultObj.enrolled = sessionResponse.data
        .map((d) => d?.enrolled)
        .flat(1)
        .filter((e) => e != null);

      if (resultObj.enrolled && resultObj.enrolled.length > 0) {
        let exixtingenroll = 0;
        if (
          resultObj.existing_enrollments &&
          resultObj.existing_enrollments.length > 0
        ) {
          //exixtingenroll=resultObj.existing_enrollments.length;
          exixtingenroll = 0;
        }
        if (resultObj.enrolled.length + exixtingenroll !== cohort.length) {
          const enrolledcorseid = resultObj.enrolled.map((item) =>
            item.id_course.toString()
          );

          const unabletojoinsession = selectedSessions.filter(
            (item) => !enrolledcorseid.includes(item.courseId)
          );

          const penddingsessions = cohort.sessions?.filter((parent) => {
            return unabletojoinsession?.some(
              (item) => item?.courseId === parent?.course_id
            );
          });
          resultObj.penddingsessions = penddingsessions;
        }
        //   var penddingsessions=_groupedSessions.filter((e)=>unabletojoinsession.courseId.includes(e.course_id));
      }

      setResult(resultObj);
      setShowResult(true);
      setShowEnrollConfirm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const enrollInCoursesAndSessions = async (
    userIds,
    groupIds,
    branchIds,
    selectedSessions
  ) => {
    var resultObj = {};
    try {
      if (userIds.length > 0) {
        setStatus("Enrolling users to learning plan..");

        const userResp = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/enrollment/users_to_lp${window.location.search}`,
          {
            userIds,
            learningPlanId: learningPlan.id,
          },
          { withCredentials: true }
        );

        resultObj.userToLpResult = userResp;
      }

      if (groupIds.length > 0) {
        setStatus("Enrolling group to learning plan..");

        const groupResp = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/enrollment/groups_to_lp${window.location.search}`,
          {
            groupIds,
            learningPlanId: learningPlan.id,
          },
          { withCredentials: true }
        );

        resultObj.groupToLpResult = groupResp;
      }

      if (branchIds.length > 0) {
        setStatus("Enrolling branches to learning plan..");

        const branchResp = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/enrollment/branches_to_lp${window.location.search}`,
          {
            branchIds,
            learningPlanId: learningPlan.id,
          },
          { withCredentials: true }
        );

        resultObj.branchToLpResult = branchResp;
      }

      let userErrors = resultObj?.userToLpResult?.data?.filter(
        (d) => d.status >= 400
      );
      let groupErrors = resultObj?.groupToLpResult?.data?.filter(
        (d) => d.status >= 400
      );
      let branchErrors = resultObj?.branchToLpResult?.data?.filter(
        (d) => d.status >= 400
      );
      //resultObj.userToLpResult.data.map(r=> console.log(r.message[0], r.message[1]))
      if (userErrors || groupErrors || branchErrors) {
        // var userErrorString = resultObj?.userToLpResult?.data.map(r => `${r.message[1]} ${r.message[0]}`)
        // var groupErrorString = resultObj?.groupToLpResult?.data.map(r => `${r.message[1]} ${r.message[0]}`)
        // var branchErrorString = resultObj?.branchToLpResult?.data.map(r => `${r.message[1]} ${r.message[0]}`)
      } else {
        setStatus("Learning plan enrollment completed..");
      }

      setStatus("Fetching sessions..");
      let getPastSessions = currentUser.isPowerUser; //power user can see past sessions
      var _groupedSessions = await getAllSessions(
        learningPlan,
        getPastSessions
      );

      setStatus("Enrolling to sessions..");
      let cohort = _groupedSessions.find(
        (x) => x.cohortId == selectedCohort.cohortId
      );
      let sessions = cohort.sessions.map((s) => {
        return { sessionId: s.id, courseId: s.course_id };
      });
      resultObj = {};

      let sessionResponse = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/enrollment${window.location.search}`,
        {
          userIds,
          groupIds,
          branchIds,
          sessions: sessions,
        },
        { withCredentials: true }
      );

      if (sessionResponse?.data) {
        // var errors = sessionResponse.data.map((d) => d.errors);
        // var existingEnrollments = [];
        // errors.forEach((error) => {
        //   if (error.existing_enrollments) {
        //     existingEnrollments.push(error.existing_enrollments);
        //   }
        //   if (error.length) {
        //     existingEnrollments.push(
        //       error
        //         .flat(1)
        //         .filter((e) => e.startsWith("The enrollments already exists"))
        //     );
        //   }
        // });

        // // const existingEnrollments = sessionResponse.data.map(d => d.errors).flat(1).filter(e => e.startsWith('The enrollments already exists'));
        // resultObj.existing_enrollments = existingEnrollments.flat(1);
        // // resultObj.errors = sessionResponse.data.map(d => d.errors).flat(1).filter(e => !e.startsWith('The enrollments already exists'));

        // //let existing = sessionResponse.data.map(e => e.errors.existing_enrollments).flat(1);

        // // for (const e of existing) {
        // //     if (e) {// if not undefined
        // //         resultObj.existing_enrollments.push(e.flat(1))
        // //     }
        // // }

        // resultObj.enrolled = sessionResponse.data
        //   .map((d) => d.enrolled)
        //   .flat(1);

        // resultObj.errors = []
        // sessionResponse.data.forEach(resp => {

        //     if (resp.errors) {
        //         if (Object.keys(resp.errors).length > 0 && Object.keys(resp.errors)[0] !== '0') { //handle case where errors are returned in json object
        //             Object.keys(resp.errors).forEach(key => {
        //                 resultObj.errors[key] ? resp.errors[key].map(e => resultObj.errors[key].push(e)) : resultObj.errors[key] = resp.errors[key]
        //             })
        //         }

        //     }

        //     // if (resp.enrolled.length > 0) {
        //     //     resultObj.enrolled ? resultObj.enrolled.map(e => resultObj.enrolled.push(e)) : resultObj.enrolled = resp.enrolled;
        //     // }
        // });
      
        const existingEnrollments = sessionResponse.data
        .map((d) => d?.errors)
        .flat(1)
        .filter((e) => e?.startsWith("The enrollments already exists"));
      resultObj.existing_enrollments = existingEnrollments;
      resultObj.errors = sessionResponse.data
        .map((d) => d?.errors)
        .flat(1)
        .filter(
          (e) => !e?.startsWith("The enrollments already exists") && e != null
        );

      resultObj.enrolled = sessionResponse.data
        .map((d) => d?.enrolled)
        .flat(1)
        .filter((e) => e != null);

      if (resultObj.enrolled && resultObj.enrolled.length > 0) {
        let exixtingenroll = 0;
        if (
          resultObj.existing_enrollments &&
          resultObj.existing_enrollments.length > 0
        ) {
          //exixtingenroll=resultObj.existing_enrollments.length;
          exixtingenroll = 0;
        }
        if (resultObj.enrolled.length + exixtingenroll !== cohort.length) {
          const enrolledcorseid = resultObj.enrolled.map((item) =>
            item.id_course.toString()
          );

          const unabletojoinsession =  selectedSessions.sessions.filter(
            (item) => !enrolledcorseid.includes(item.courseid)
          );

          const penddingsessions = cohort.sessions?.filter((parent) => {
            return unabletojoinsession?.some(
              (item) => item?.courseid === parent?.course_id
            );
          });
        
          resultObj.penddingsessions = penddingsessions;
        }
        //   var penddingsessions=_groupedSessions.filter((e)=>unabletojoinsession.courseId.includes(e.course_id));
      }
      }
    } catch (error) {
      resultObj.errors = error;
      console.error(error);
    }

    setResult(resultObj);
    setShowResult(true);
    setShowEnrollModal(false);
  };

  const getAllSessions = async (learningPlan, getPastSessions) => {
    let learningPlanCourses = learningPlan.courses;

    let courseIds = learningPlanCourses.map((course) => course.id_course);
    //var courseIds = learningPlanCourses.map(course => course.id_course)
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/session`,
      {
        courseIds,
      }
    );
    if (response.data.length > 0) {
      let sessions = response.data.filter(
        (session) =>
          session.additional_fields.length > 0 &&
          session.additional_fields.find((field) => field.title === "CohortID")
            .value
      );
      //setSessions(sessions);
      sessions.sort((a, b) => a.start_date < b.start_date);

      if (!getPastSessions) {
        sessions = sessions.filter(
          (session) => new Date(session.start_date) >= new Date()
        );
      }

      let AllCohorts = sessions.map(
        (s) =>
          s.additional_fields.find((field) => field.title === "CohortID").value
      );
      let uniqueCohorts = [...new Set(AllCohorts)];

      var _groupedSessions = uniqueCohorts.map((cohort) => {
        return {
          cohortId: cohort,
          sessions: sessions.filter(
            (session) =>
              session.additional_fields.find(
                (field) => field.title === "CohortID"
              ).value === cohort
          ),
        };
      });
    }
    return _groupedSessions;
  };


  // Function to get the day of the week (e.g., "Mon")
const getDayOfWeek = (date) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return daysOfWeek[date.getDay()];
};

// Function to get the month, day, and year (e.g., "1/1/2024")
const getMonthDayYear = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

// Function to get the hour and minute (e.g., "8:00")
const getHourMinute = (date) => {
  let hour = date.getHours();
  const minute = date.getMinutes();
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
 hour = hour ? hour : 12; // Handle midnight (0 hours)
  return `${hour}:${minute.toString().padStart(2, '0')}`;
};

// Function to get the AM/PM indicator
const getAMPM = (date) => {
  return date.getHours() >= 12 ? ' PM' : ' AM';
};

const convertTime = (inputTime) => {
  // Check if the input time contains ':', if yes, it represents hours and minutes
  if (inputTime.includes(':')) {
    const [hours, minutes] = inputTime.split(':').map(Number);
    if (hours > 0 && minutes === 0) {
      // If hours are greater than zero and minutes are zero, display only hours
      return `${hours} hours`;
    } 
    else if(hours === 0 && minutes > 0){
      // If the input time does not contain ':', it represents only minutes
      
      return `${minutes} minutes`; // Format the result
    }
    else {
      // Display both hours and minutes
      return `${hours} hours ${minutes} minutes`;
    }
  } else {
    // If the input time does not contain ':', it represents only minutes
    const minutes = Number(inputTime);
    return `${minutes} minutes`; // Format the result
  }
};

//Added by ES 13 Jun - New method
const getCourseName = (sessionForCourse) => {
  var courseName="";
 // var data=sessionsForCourse.filter(x=> x.id==sessionForCourse.id);
 
 var courseNameList=learningPlan.courses.filter(x=> x.id_course==sessionForCourse.courseid);
  //var courseNameList=learningPlan.courses.filter(x=> x.id_course==data[0].course_id);
 //name
 if(courseNameList.length>0)
  {
    courseName=courseNameList[0].name;
  }
  return courseName;
};
const getCourseNamebyId = (courseId) => {
  var courseName="";
 // var data=sessionsForCourse.filter(x=> x.id==sessionForCourse.id);
 
 var courseNameList=learningPlan.courses.filter(x=> x.id_course==courseId);
  //var courseNameList=learningPlan.courses.filter(x=> x.id_course==data[0].course_id);
 //name
 if(courseNameList.length>0)
  {
    courseName=courseNameList[0].name;
  }
  return courseName;
};
const getEnrollCount =    (session) => {
 
  if(SessionDtlList.length>0)
  {
  var count=SessionDtlList.filter(x=>x.id==session.id);
 
//   const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/enrollmentcount`, {
//     CourseId: session.courseid,SessionId:session.id

// });
//   return response.data;
if(count.length>0)
{
  return count[0].enrolled;
}
else{
  return "Not Found";
}
  
  }
  else
  {
    return "-1";
  }
};

const getStartDateTime = (sessionForCourse) => {
  
  var data=sessionsForCourse.filter(x=> x.id==sessionForCourse.id);
  
  var startDateTime=data[0].begin_day+" "+data[0].begin_time;
 
  return startDateTime;
};

// till here

  // const enrollUsers = () => {
  //     console.log('enrolling users')
  // }

  // const enrollGroups = () => {
  //     console.log('enrollGroups')
  // }

  // const enrollBranches = () => {
  //     console.log('enrollBranches')
  // }

  // const onModelCloseHandler = () => {
  //     setShowEnrollModal(false);
  // }

  // useEffect(() => {
  //     if (currentUser.isPowerUser) {

  //         setIsListBoxLoading(true);
  //         if (currentRadioValue) {
  //             setDefaultListOptions([]);
  //             var defaultFn;
  //             switch (currentRadioValue) {
  //                 case 'users':
  //                     defaultFn = searchUsers
  //                     break;
  //                 case 'groups':
  //                     defaultFn = searchGroups
  //                     break;
  //                 case 'branches':
  //                     defaultFn = searchBranches
  //                     break;
  //                 default:
  //                     break;
  //             }

  //             const getDefaultOptions = async () => {
  //                 var options = await defaultFn('');
  //                 setDefaultListOptions(options);
  //                 setIsListBoxLoading(false);
  //             }
  //             getDefaultOptions();
  //         }
  //     }
  // }, [currentRadioValue])

  // return (
  //     <>
  //         <div className='container card mt-3'>
  //             <div className='card-header'>
  //                 <a className='btn btn-link p-3 float-start' onClick={() => goBack()}><iconify-icon icon="zmdi:chevron-left" width="7" /> back</a>
  //             </div>
  //             <div className='card-body text-start'>

  //                 <h5 className='card-title p-3'>{learningPlan.name}</h5>
  //                 {
  //                     (sessions.length > 0 ?
  //                         (
  //                             <ul className='list-group list-group-flush'>
  //                                 {
  //                                     sessions.map((groupedSession, i) => {

  //                                         return <li className='list-group-item' key={i}>
  //                                             <div className='row'>
  //                                                 <div className='col-7'>
  //                                                     <div className='row'>
  //                                                         <div className='col'>
  //                                                             {/* <span>Cohort: </span> */}
  //                                                             <span className='d-block'>Session Name: {groupedSession.sessions[0].name}</span>
  //                                                             {locations.find(x => x.id == groupedSession.sessions[0].current_location) && <span className='d-block'>Location: {locations.find(x => x.id == groupedSession.sessions[0].current_location).name}</span>}
  //                                                             {groupedSession.sessions[0].additional_fields.find(field => field.title === "Network").value && <span className='d-block'>Network: {groupedSession.sessions[0].additional_fields.find(field => field.title === "Network").value}</span>}
  //                                                             <span className='d-block'>Start date: {groupedSession.sessions[0].start_date.split(' ')[0]}</span>
  //                                                             {/* <span className='d-block'>Start time: {groupedSession.sessions[0].start_date.split(' ')[1]}</span> */}
  //                                                         </div>
  //                                                     </div>

  //                                                     {/* <span className='d-block'>Location: {groupedSession.sessions[0].start_date}</span>
  //                                                     <span className='d-block'>Language: {groupedSession.sessions[0].start_date}</span> */}
  //                                                 </div>
  //                                                 <div className='col'>
  //                                                     <button className='btn primary' onClick={(e) => onViewSessionClickHandler(groupedSession)}>VIEW SESSION</button>&nbsp;&nbsp;&nbsp;
  //                                                     <button className='btn primary' onClick={(e) => onEnrollClickHandler(groupedSession)}>ENROLL</button>
  //                                                 </div>
  //                                             </div>
  //                                         </li>

  //                                     })
  //                                 }
  //                             </ul>
  //                         ) : <h6 className='text-danger text-center color-grey pt-5'>No sessions found!</h6>)
  //                 }
  //             </div>
  //         </div>
  //         {
  //             currentUser.isPowerUser &&
  //             <Modal centered show={showEnrollModal}>
  //                 <ModalHeader >
  //                     <Modal.Title>Select users</Modal.Title>
  //                 </ModalHeader>
  //                 <Modal.Body>
  //                     <div className='row'>
  //                         {/* <Form>
  //                             <div className="mb-3" onChange={(e) => setCurrentRadioValue(e.target.value)} >
  //                                 <Form.Check defaultChecked="true" type='radio' inline label="users" value="users" name='searchTypeRadio' />
  //                                 <Form.Check type='radio' inline label="groups" value="groups" name='searchTypeRadio' />
  //                                 <Form.Check type='radio' inline label="branches" value="branches" name='searchTypeRadio' />
  //                             </div>

  //                         </Form> */}
  //                         <div className='mb-3'>
  //                             <Select
  //                                 ref={enrollSelectBox}
  //                                 options={defaultListOptions}
  //                                 isMulti
  //                                 onChange={setSelectBoxValues}
  //                                 isLoading={isListBoxLoading}
  //                                 filterOption={filterOptions}
  //                             />
  //                             {/* <AsyncSelect
  //                                 ref={enrollSelectBox}
  //                                 isMulti
  //                                 isLoading={isListBoxLoading}
  //                                 defaultOptions={defaultListOptions}
  //                                 //loadOptions={promiseOptions}
  //                                 onInputChange={handleSelectInputChange}
  //                                 onChange={setSelectBoxValues}
  //                             /> */}

  //                         </div>
  //                     </div>
  //                     <div hidden={!loading} className="">
  //                         <div>{status}</div>

  //                     </div>
  //                 </Modal.Body>
  //                 <Modal.Footer>
  //                     <button className='btn btn-secondary' onClick={() => setShowEnrollModal(false)}>Close</button>
  //                     <button className='btn secondary' disabled={loading} onClick={(e) => onModalEnrollClickHandler(e)}>
  //                         <Spinner
  //                             className='me-1'
  //                             as="span"
  //                             animation="border"
  //                             size="sm"
  //                             role="status"
  //                             aria-hidden="true"
  //                             hidden={!loading}
  //                         />
  //                         Enroll
  //                     </button>
  //                 </Modal.Footer>
  //             </Modal>
  //         }
  //         <Modal centered show={showEnrollConfirm}>
  //             <Modal.Header>
  //                 <Modal.Title>Confirm enroll</Modal.Title>
  //             </Modal.Header>
  //             <Modal.Body>
  //                 <p>Enroll to session?</p>
  //             </Modal.Body>
  //             <Modal.Footer>
  //                 <button className='btn btn-secondary' onClick={() => setShowEnrollConfirm(false)}>Close</button>
  //                 <button className='btn secondary' disabled={loading} onClick={(e) => onConfirmEnrollClick(e)}>
  //                     <Spinner
  //                         className='me-1'
  //                         as="span"
  //                         animation="border"
  //                         size="sm"
  //                         role="status"
  //                         aria-hidden="true"
  //                         hidden={!loading}
  //                     />
  //                     Enroll
  //                 </button>
  //             </Modal.Footer>
  //         </Modal>

  //         <div className="toast-container top-50 start-50 translate-middle" style={{ zIndex: 11 }}>
  //             <div id="resultToast" className='toast hide' role="alert" aria-live="assertive" aria-atomic="true">
  //                 <div className="toast-header">
  //                     {/* <img src="..." className="rounded me-2" alt="..." /> */}
  //                     <strong className="me-auto">Completed</strong>
  //                     <small></small>
  //                     <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => { setShowResult(false) }}></button>
  //                 </div>
  //                 <div className="toast-body">
  //                     {result.enrolled && result.enrolled.length > 0 && <span className='text-success d-block'>Successfully enrolled in {result.enrolled.length} sessions</span>}
  //                     {result.existing_enrollments && result.existing_enrollments.length > 0 && <span className='text-danger d-block'>Already enrolled in {result.existing_enrollments.length} sessions</span>}
  //                     {/* {result.errors && <span className='text-danger'>Already enrolled in {result.existing_enrollments.length} sessions</span>} */}
  //                 </div>
  //             </div>
  //         </div>
  //     </>
  // )
  //added by excel soft to modifing the UI
  return (
    <>
      <div className="container card mt-3">
        <div className="card-header">
          <a className="btn btn-link p-3 float-start" onClick={() => goBack()}>
            <iconify-icon icon="zmdi:chevron-left" width="7" /> back
          </a>
        </div>
        <div className="card-body text-start">
          <h5 className="card-title p-3">{learningPlan.name}</h5>
          {sessions.length > 0 ? (
            <ul className="list-group list-group-flush">
              {sessions.map((groupedSession, i) => {
                //const date =getStartDateTime(groupedSession.sessions[0]);
                return (
                  <li className="list-group-item" key={i}>
                    <div className="row">
                      <div className="col-7">
                        <div className="row">
                          <div className="col">
                            {/* <span>Cohort: </span> */}
                            <span className="d-block">
                              Session Name: {groupedSession.sessions[0].name}
                            </span>
                            {groupedSession.sessions[0].location != null && (
                              <span className="d-block">
                                Location:
                                {groupedSession.sessions[0].location.name}
                              </span>
                            )}
                            {groupedSession.sessions[0].additional_fields.find(
                              (field) => field.name === "Network"
                            ).value && (
                              <span className="d-block">
                                Network:{" "}
                                {
                                  groupedSession.sessions[0].additional_fields.find(
                                    (field) => field.name === "Network"
                                  ).value
                                }
                              </span>
                            )}
                              {
                              <span className="d-block">
                                Start date:{" "}
                                {
                                groupedSession.sessions[0].startdatetime
                                  .split(
                                    " "
                                  )[0]
                                }
                              </span>
                            }
                            {
                              <span className="d-block">
                                Start time:{" "}
                                {
                                  groupedSession.sessions[0].startdatetime.split(
                                    " "
                                  )[1]
                                }
                              </span>
                            }

                            {
                              <span className="d-block">
                                Seat count:{" "}
                                {groupedSession.sessions[0].max_enroll}
                              </span>
                            }
                          </div>
                        </div>

                        {/* <span className='d-block'>Location: {groupedSession.sessions[0].start_date}</span>
                                                        <span className='d-block'>Language: {groupedSession.sessions[0].start_date}</span> */}
                      </div>
                      <div className="col">
                        <button
                          className="btn primary"
                          onClick={(e) => onEnrollClickHandler(groupedSession)}
                        >
                          ENROLL
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          className="btn primary"
                          onClick={(e) =>
                            onViewSessionClickHandler(groupedSession)
                          }
                        >
                          View All Class Series
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <h6 className="text-danger text-center color-grey pt-5">
              No sessions found!
            </h6>
          )}
        </div>
      </div>
      {currentUser.isPowerUser && (
        <Modal centered show={showEnrollModal}>
          <ModalHeader>
            {/* <Modal.Title>Select users</Modal.Title> */}
          </ModalHeader>
          <Modal.Body>
            <div className="row">
              <div className="mb-3">
                Select group{" "}
                <Select
                  ref={enrollSelectBox}
                  options={defaultListGroupOptions}
                  onChange={handleSelectChangeGroup}
                  isLoading={isListBoxLoading}
                  filterOption={filterOptions}
                />
              </div>

              {/* <Form>
                                <div className="mb-3" onChange={(e) => setCurrentRadioValue(e.target.value)} >
                                    <Form.Check defaultChecked="true" type='radio' inline label="users" value="users" name='searchTypeRadio' />
                                    <Form.Check type='radio' inline label="groups" value="groups" name='searchTypeRadio' />
                                    <Form.Check type='radio' inline label="branches" value="branches" name='searchTypeRadio' />
                                </div>

                            </Form> */}
              <div className="mb-3">
                Select users{" "}
                <Select
                  ref={enrollSelectBox}
                  options={defaultListOptions}
                  isMulti
                  onChange={setSelectBoxValues}
                  isLoading={isListBoxLoading}
                  filterOption={filterOptions}
                />
                {/* <AsyncSelect
                                    ref={enrollSelectBox}
                                    isMulti
                                    isLoading={isListBoxLoading}
                                    defaultOptions={defaultListOptions}
                                    //loadOptions={promiseOptions}
                                    onInputChange={handleSelectInputChange}
                                    onChange={setSelectBoxValues}
                                /> */}
              </div>
            </div>
            <div hidden={!loading} className="">
              <div>{status}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setShowEnrollModal(false);
                setDefaultListOptions([]);
              }}
            >
              Close
            </button>
            <button
              className="btn secondary"
              disabled={loading}
              onClick={(e) => onModalEnrollClickHandler(e)}
            >
              <Spinner
                className="me-1"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!loading}
              />
              Enroll
            </button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal centered show={showEnrollConfirm}>
        <Modal.Header>
          <Modal.Title>Confirm enroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enroll to session?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowEnrollConfirm(false)}
          >
            Close
          </button>
          <button
            className="btn secondary"
            disabled={loading}
            onClick={(e) => onConfirmEnrollClick(e)}
          >
            <Spinner
              className="me-1"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              hidden={!loading}
            />
            Enroll
          </button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" centered show={showViewSessionConfirm} id="sessionDetails">
  <Modal.Header>
    <Modal.Title className="h5">Class Series Details</Modal.Title>
    <button
      type="button"
      className="btn-close"
      aria-label="Close"
      onClick={() => {
        setShowViewSessionConfirm(false);
        setotherSessionList([]);
      }}
    ></button>
  </Modal.Header>
  <Modal.Body>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      
      </div>
    ) : otherSessionList.length > 0 ? (
      <table className="table">
        <thead>
          <tr>
            <th>Course Name</th>
            <th>Session Name</th>
            <th>Location</th>
            <th>Start Date/Time</th>
            <th>Duration</th>
            <th>Seats Taken</th>
            <th>Total Seats</th>
          </tr>
        </thead>
        <tbody>
          {otherSessionList.map((groupedSession, i) => {
            const popupdate = groupedSession.startdatetime;
            return (
              <tr key={i}>
                <td>{getCourseName(groupedSession)}</td>
                <td>{groupedSession.name}</td>
                <td>
                  {groupedSession.location != null &&
                    groupedSession.location.name}
                </td>
                <td>
                  {`${getDayOfWeek(new Date(popupdate))}, ${getMonthDayYear(
                    new Date(popupdate)
                  )} ${getHourMinute(new Date(popupdate))}${getAMPM(
                    new Date(popupdate)
                  )}`}
                </td>
                <td>{convertTime(groupedSession.hours)}</td>
                <td>{getEnrollCount(groupedSession)}</td>
                <td>{groupedSession.max_enroll}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : (
      <h6 className="text-danger text-center color-grey pt-5">
        No sessions found!
      </h6>
    )}
    Total {otherSessionList.length} Session(s)
  </Modal.Body>
  <Modal.Footer>
    <button
      className="btn btn-secondary"
      onClick={() => {
        setShowViewSessionConfirm(false);
        setotherSessionList([]);
      }}
    >
      Close
    </button>
  </Modal.Footer>
</Modal>
      <div className="resultToastContainerClass" id="resultToastContainer">
        <div
          id="resultToast"
          className="toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{ width: "450px" }}
        >
          <div className="toast-header">
            {/* <img src="..." className="rounded me-2" alt="..." /> */}
            <strong className="me-auto">Completed</strong>
            <small></small>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => {
                setShowResult(false);
              }}
            ></button>
          </div>
          <div className="toast-body tostbodyclass">
            {result.enrolled && result.enrolled.length > 0 && (
              <div><div className="toastpopup">
                {" "}
                <span className="text-success d-block fs-6">
                  Successfully enrolled in {result.enrolled.length} out of{" "}
                  {selectedCohort.sessions.length} sessions.
                  {result.penddingsessions &&
                    result.penddingsessions.length > 0 && (
                      <div>
                        <span className="text-danger d-block fs-6">
                          Unable to enroll in these sessions:
                        </span>
                        <ul>
                          {/* Render filteredArray as list items */}
                          {result.penddingsessions.map((item) => (
                            <li
                              key={item.name}
                              style={{ color: "blue", fontSize: "smaller" }}
                            >
{getCourseNamebyId(item.course_id)} <br/>
                              Session: {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </span>
                
              </div>
              <div
                  className="flex mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onEnrolledCompleteOkClickHandler();
                    }}
                  >
                    Ok
                  </button>
                </div></div>
            )}
            {result.existing_enrollments &&
              result.existing_enrollments.length > 0 && (
                <div><div className="toastpopup">
                  <span className="text-danger d-block fs-6">
                    Already enrolled in {result.existing_enrollments.length} out
                    of {selectedCohort.sessions.length} sessions
                  </span>
                
                </div>
                  <div
                  className="flex mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onEnrolledCompleteOkClickHandler();
                    }}
                  >
                    Ok
                  </button>
                </div></div>
              )}
            {result.enrolled &&
              result.enrolled.length == 0 &&
              result.existing_enrollments &&
              result.existing_enrollments.length == 0 && (
               <div> <div className="toastpopup">
                  <span className="text-success d-block fs-6">
                    Successfully enrolled in 0 out of{" "}
                    {selectedCohort.sessions.length} sessions
                  </span>
                 
                </div>
                 <div
                 className="flex mt-3"
                 style={{
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                 }}
               >
                 <button
                   class="btn btn-secondary"
                   onClick={() => {
                     onEnrolledCompleteOkClickHandler();
                   }}
                 >
                   Ok
                 </button>
               </div></div>
              )}
            {/* {result.errors && <span className='text-danger'>Already enrolled in {result.existing_enrollments.length} sessions</span>} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sessions;
